@media only screen and (max-width: 1199px) {
    h1 {
        font-size: 50px;
    }
    .home-header-background {
        .TextBox {
            h1,
            .h1 {
                font-size: 50px;
            }
        }
    }
    .home-header-background .thema-overzicht .thema-overzicht-boxes .h5,
    .home-header-background .thema-overzicht .thema-overzicht-boxes h5 {
        font-size: 20px;
    }
    .BtnBlue {
        font-size: 14px;
    }
    .ThemaLogo {
        .position-absolute {
            right: 0;
        }
    }
    #ObjectiveTabs {
        .nav-item {
            .nav-link {
                width: 120px;
                font-size: 0.6rem;
                &:after {
                    border-left-width: 60px;
                    border-right-width: 60px;
                }
            }
        }
    }
    #EnforcementBottles {
        ul {
            li {
                flex: 0 0 33.33%;
            }
        }
    }

    .pager {
        ul {
            width: 20% !important;
        }
    }
    .header-title-custom {
        font-size: 2.3rem;
    }
}
@media only screen and (max-width: 991px) {
    h1 {
        font-size: 38px;
    }
    h3 {
        font-size: 28px;
    }

    .home-header-background {
        .TextBox {
            h1,
            .h1 {
                font-size: 40px;
            }
        }
    }
    .ThemaLogo {
        .position-absolute {
            width: 200px;
            svg {
                max-width: 200px;
                height: auto;
            }
        }
    }
    .home-over-ons {
        .svg-container {
            height: 200px;
        }
    }
    .sub-navigation .navigation-container {
        a {
            font-size: 14px;
        }
    }
    #ObjectiveTabs {
        .nav-item {
            .nav-link {
                width: 105px;
                &:after {
                    border-left-width: 52px;
                    border-right-width: 52px;
                }
            }
        }
    }
    #EnforcementBottles {
        ul {
            li {
                flex: 0 0 50%;
            }
        }
    }
    .ThemaLogo {
        width: 154px;
        height: 154px;
    }

    .header-title-custom {
        font-size: 1.8rem;
    }
}

@media only screen and (max-width: 767px) {
    h1 {
        font-size: 30px;
    }
    h3 {
        font-size: 28px;
    }
    .home-header-background {
        .TextBox {
            h1,
            .h1 {
                font-size: 30px;
            }
        }
    }
    .home-header-background {
        .thema-overzicht {
            h3,
            .h3 {
                font-size: 26px;
            }
        }
    }
    .ThemaLogo {
        .position-absolute {
            position: static !important;
            width: 100%;
        }
    }

    #VideoPopUp iframe {
        height: 300px;
    }
    #ObjectiveTabs {
        margin-top: 0;
        .nav-item {
            padding-bottom: 10px;
            .nav-link {
                width: 100%;
                height: auto;
                background-image: none !important;
                padding: 12px;
                font-size: 0.75rem;
                &:before,
                &:after {
                    content: none;
                }
            }
        }
    }

    .sub-navigation .navigation-container {
        .BeforeBorder {
            &:before {
                content: none;
            }
            &.last {
                border-right: none;
            }
        }
        .vertical-line {
            border-left: none;
        }
    }

    .popup-container {
        h3 {
            padding-left: 2rem;
        }
        .column-left-content {
            padding: 0 2rem;
        }
        .column-right-content {
            padding: 0 2rem;
        }

        .close-icon {
            color: $primary;
            padding-right: 2rem;
        }
    }
    .header-title-custom {
        font-size: 1.2rem;
    }
}
@media only screen and (max-width: 575px) {
    h3 {
        font-size: 22px;
    }
    .BtnPurple,
    .BtnBlue,
    .BtnWhite {
        font-size: 16px;
    }

    #VideoPopUp iframe {
        height: 230px;
    }

    #EnforcementBottles h3 {
        word-break: break-all;
    }

    .popup-container .popup-dimensions {
        max-width: 100%;
    }
    #EnforcementBottles {
        ul {
            li {
                flex: 0 0 100%;
            }
        }
    }
}
