.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
 }

 .content-block {
     // Voor content op hoogste niveau
     padding-top: 3rem;
     padding-bottom: 1rem;
 }

 .arrow-down {
    // TODO: Jaap; deze staat er ruim 20 keer op verschillende plekken
    font-size: 2rem;
}
