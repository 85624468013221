.projectblock {
    .icon {
        border-radius: 50%;
        background-color: white;
        height: 80px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 80%;
            max-height: 80%;
        }
    }
}