.riec_type {
    .openpopup-container {
        h3 {
            color: $success;
        }

        .openpopup {
            background-color: $flower;
        }
    }
}

.nh_type {
    .openpopup-container {
        h3 {
            color: $dark;
        }


    }

    .openpopup {
        background-color: $light;
    }
}

.nh_type {

    .activity-header,
    .light-background {
        background-color: $light;
    }

    .openpopup {
        background-color: $light;
    }
}

.openpopup {
    border: none;
    display: flex;
    border: none;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    color: $primary;
    padding: 0.5rem 1rem;
    margin-bottom: 1.25rem;

    i {
        font-size: 1.2rem;
    }
}





.riec_type {

    .activity-header,
    .light-background {
        background-color: $flower;
    }
}

.activity-header {
    height: 6rem;
    display: flex;
    align-items: center;

    h1 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0;
    }

    i {
        font-size: 1.5rem;
    }
}


.openpopup-container {
    .horizontal-dotted {
        border-bottom: 1px dotted $success;
        height: 1px;
        margin: 2rem auto;
    }

    .openpopup {
        background-color: $light;
        border: none;
    }

    h3 {
        font-size: 1.5rem;
        font-weight: 400;
        // margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .openpopup {
        display: flex;
        border: none;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        font-weight: 600;
        color: $primary;
        padding: 0.5rem 1rem;
        margin-bottom: 1.25rem;

        i {
            font-size: 1.2rem;
        }
    }


}


.project-toggle {
    background-color: #fff;
    margin-bottom: 1rem;

    &.project-toggle-bar {
        display: flex;
    }

    &.project-toggle-hidden {
        display: none;
    }

    .close-item-row {
        font-size: 1.5rem;
        transition: transform .5s ease-out;
    }

    &:hover {
        .close-item-row {
            transform: rotate(180deg);
        }
    }
}