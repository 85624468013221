$riec-purple: #80217e;

$primary: #480e44;
/* donker paars */
$secondary: #fff;
/* wit */
$success: #80217e;
/* licht paars */
$info: #f5eff5;
/* licht roze */
$warning: #ad8116;
/* bruin */
$danger: #e3a51a;
/* oker geel */
$light: #e2eef0;
/* baby-blauw */
$dark: #00929f;
/* vel blauw */
$verydark: #00626a;
/* diep blauw */
$mouse: #edf4f5;
/* light grey */
$flower: #f6ecf7;
/* light purple */
$seconds: #eed8e9;
/* light pink */
