#navbarNavDropdown {
    padding: 1rem;
    transition: width .3s ease-in-out;
    overflow-x: hidden;

    ul {
        padding-left: 0;
    }

    width: 18rem;
    background-color: #480e44;

    input {
        border-radius: 0;
        max-width: 13rem;
    }

    a,
    h5 {
        color: white;
    }

    $menu-width: 21rem;

    @media only screen and (max-width: 60rem) {

        // Menu for smaller screen
        &.level2 {
            width: $menu-width;

            .level1menu {
                left: -$menu-width;
            }

            .level2menu {
                left: $menu-width;
            }
        }

        &.level3 {
            width: $menu-width;

            .level1menu {
                left: -$menu-width;
            }

            .level2menu {
                left: -$menu-width;
            }

            .level3menu {
                left: $menu-width * 2;
            }
        }

        .level1menu,
        .level2menu,
        .level3menu {
            position: absolute;
            top: 0;
            width: $menu-width;
            transition: left .4s ease-in-out;
        }

    }

    @media only screen and (min-width: 60rem) {
        // Menu for smaller desktop
        
        .back-item {
            display: none;
        }
        &.level2 {
            width: 31rem;
        }

        &.level3 {
            width: 55rem;
        }

        .level1menu {
            width: 14rem;
        }

        .level2menu {
            left: 15rem;
            width: 15rem;
        }

        .level3menu {
            left: 25rem;
            width: 22rem;
        }

        .level2menu,
        .level3menu {
            position: absolute;
            top: 0;
        }

        .level3menu {
            position: absolute;
            left: 100%;
        }
    }
}

/*--- Common Style ---*/
.navigation-bar {

    &.MainMenu {
        min-height: 2rem;
    }

    .back {
        background-color: white;
    }

    .fixed-top {
        width: 100%;
        @include fixed(0, 0, inherit, 0);

    }

    .navbar {
        z-index: 2;
        left: calc(var(--bs-gutter-x) / 2)
    }

}