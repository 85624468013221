.logo-container {
    img {
        margin: auto;
        max-width: 80%;
    }
}

.blue-icon {
    filter: invert(44%) sepia(37%) saturate(7246%) hue-rotate(160deg) brightness(91%) contrast(101%);
    height: 100% !important;
    width: 100% !important;
}